import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { format, getWeek } from 'date-fns';
import { da } from "date-fns/locale";
import { ResponseDetails } from "./swaggerClientUtils";

export async function saveFile(response: ResponseDetails, fallbackFileName?: string) {
    if(!response.rawData) {
        throw new Error("Could not fetch raw data from response.");
    }

    const blob = new Blob([response.rawData], {
        type: 'text/csv'
    });

    const headers = response.headers;

    const contentDispositionHeader = headers?.get("Content-Disposition");
    const contentDispositionKeyValuePairs = contentDispositionHeader
        ?.split(';')
        .map(x => x
            .trim()
            .split('='));

    const filenamePair = contentDispositionKeyValuePairs?.find(x => x[0] === "filename");
    const filename = filenamePair ?
        filenamePair[1] :
        fallbackFileName;

    const saveAsImport = await import('file-saver');
    saveAsImport.default.saveAs(blob, filename);
}

export function delay(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export const convertMaterialUiPickersDateToDate = (materialUiPickersDate: MaterialUiPickersDate) => {
    if (materialUiPickersDate == null) {
        return null;
    }
    return new Date(materialUiPickersDate.toISOString());
}

export const toDanishDateFormat = (date: Date | null | undefined, formatStr = 'PPp') => {
    if (!date) {
        return "";
    }
    if (typeof(date) === 'string') {
        date = new Date(date);
    }
    return format(date, formatStr, {
        locale: da
      });
}

export const toDanishDateFormatWithOutTime = (date: Date | null | undefined, formatStr = 'PP') => {
    if (!date) {
        return "";
    }
    if (typeof(date) === 'string') {
        date = new Date(date);
    }
    return format(date, formatStr, {
        locale: da
      });
}

export const getDanishWeek = (date: Date | null | undefined) => {
	if (!date) {
		 return null;
	}
	if (typeof(date) === 'string') {
		 date = new Date(date);
	}
	return getWeek(date, {
		 locale: da
	  });
}