import DocumentationPage from "@components/DocumentationPage";
import ReshopperButton from "@components/web/Button";
import { delay } from "@utils/miscellaneous";
import React from "react";

export default () => {
	const variants = ["contained", "outlined", "text"];
	const sizes = ["small", "medium", "large"];
	const colors = ["primary", "secondary", "tertiary"];
	const disabled = [true, false];

	const components = new Array<React.ReactNode>();
	for (const variant of variants) {
		for (const size of sizes) {
			for (const color of colors) {
				for (const isDisabled of disabled) {
					components.push(
						<ReshopperButton
							variant={variant as any}
							size={size as any}
							color={color as any}
							onClick={async () => await delay(1000)}
							disabled={isDisabled}
							key={`${variant}-${size}-${color}`}
						>
							{variant} {size} {color} {isDisabled ? "disabled" : "enabled"}
						</ReshopperButton>
					);
				}
			}
		}
	}

	return <DocumentationPage>
		{components}
	</DocumentationPage>;
}